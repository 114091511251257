import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function ActionsEnd({
  onClickFinished,
  visible,
}) {
  if (!visible) {
    return null;
  }

  return (
    <div className="actions in-game">
      <Container fluid>
        <Row>
          <Col className="d-flex justify-content-end align-items-center">
            <p className="mb-0">
              Stations that you found are labelled in <span className="found">blue</span>.
              The ones that you missed are labelled in <span className="missed">red</span>.
            </p>
            <Button variant="success" onClick={onClickFinished} className="ms-3">
              Finished browsing
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

ActionsEnd.propTypes = {
  onClickFinished: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

ActionsEnd.defaultProps = {
  visible: false,
};

export default ActionsEnd;
