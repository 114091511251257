import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function ModalIntro({ onStart, totalStations, visible }) {
  return (
    <Modal backdrop="static" keyboard={false} size="lg" show={visible}>
      <Modal.Header>
        <Modal.Title>I Know The Tube</Modal.Title>
        <h2>London Underground Stations Quiz</h2>
      </Modal.Header>
      <Modal.Body>
        <p>
          How well do you know the London Underground map? This map contains
          all 11 main lines, plus London Overground, DLR, Emirates Air Line,
          Tramlink and TFL Rail for a grand total of {totalStations} individual
          stations, but <strong>all the names are missing</strong>.
        </p>
        <p>
          Score points for remembering obscure stations and race against the
          clock to beat your friends!
        </p>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4928649706396561"
          crossOrigin="anonymous"
        />
        <ins
          className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-4928649706396561"
          data-ad-slot="5328325988"
          data-ad-format="auto"
          data-full-width-responsive="true"
        />
        <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={onStart}>Start game</Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalIntro.propTypes = {
  onStart: PropTypes.func.isRequired,
  totalStations: PropTypes.number.isRequired,
  visible: PropTypes.bool,
};

ModalIntro.defaultProps = {
  visible: true,
};

export default ModalIntro;
