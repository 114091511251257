import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Map from '../svg/map.svg';
import useWindowSize from '../utils/use-window-size';
import ActionsPlaying from './game/components/actions-playing';
import ActionsEnd from './game/components/actions-end';
import ModalIntro from './game/components/modal-intro';
import ModalStop from './game/components/modal-stop';
import ModalEnd from './game/components/modal-end';

import '../styles/index.scss';

const BASE_SCORE = 5;
const TIME_LIMIT = 30 * 60;

function stringToStationId(str) {
  return str.replace(/[\s'’()-]*/g, '').replace('&', 'and').toLowerCase();
}

function hideElems(elems) {
  [...elems].forEach((node) => {
    node.style.display = 'none';
  });
}

function showElems(elems) {
  [...elems].forEach((node) => {
    node.style.display = 'block';
  });
}

function IndexPage() {
  const svg = useRef(null);
  const windowSize = useWindowSize();
  const [modalIntroVisible, setModalIntroVisible] = useState(true);
  const [modalStopVisible, setModalStopVisible] = useState(false);
  const [modalEndVisible, setModalEndVisible] = useState(false);
  const [actionsVisible, setActionsVisible] = useState(true);
  const [actionsEndVisible, setActionsEndVisible] = useState(false);
  const [stationNameElems, setStationNameElems] = useState(null);
  const [gameInProgress, setGameInProgress] = useState(false);
  const [gameTimeElapsed, setGameTimeElapsed] = useState(0);
  const [gameTotalStations, setGameTotalStations] = useState(0);
  const [gameFoundStations, setGameFoundStations] = useState(0);
  const [gameAllStations, setGameAllStations] = useState(null);
  const [gameStations, setGameStations] = useState(null);
  const [gameZoomer, setGameZoomer] = useState(null);
  const [gameTimer, setGameTimer] = useState(null);
  const [gameScore, setGameScore] = useState(0);

  useEffect(() => {
    // Get a reference to the actual SVG element in the DOM. Slightly hacky but
    // we have to go via a parent wrapper element because the webpack loader for
    // inline SVGs as React components doesn't forward refs.
    const elem = svg.current.querySelector('svg');
    const zoom = require('svg-pan-zoom');

    // Enable pan/zoom on the SVG element.
    const zoomer = zoom(elem, {
      minZoom: 1,
      maxZoom: 8,
      fit: true,
    });
    setGameZoomer(zoomer);

    // Set the starting zoom level.
    zoomer.zoom(3);

    //
    // Game setup.
    //

    // Get the SVG text elements containing the station names. The two special
    // cases in the selector are anomalies in the original SVG and do not have
    // the "label" suffix in their id. Additionally, their ids begin with a
    // number which is invalid so we have to use a suffix selector.
    const nameElems = document.querySelectorAll('[id$=GACTONML], [id$=GZZLUBSC], #station-names [id$=label]');
    setStationNameElems(nameElems);

    // Build a map of relevent elements to station name. Station names are
    // turned into ids by removing whitespace and lowercasing.
    const elemsById = [...nameElems].reduce((obj, node) => {
      const id = stringToStationId(node.textContent);

      obj[id] = obj[id] || [];
      obj[id].push(node);

      return obj;
    }, {});

    // Special case - we already have Tower Hill listed on its own, we don't
    // want this additional record that also includes Fenchurch Street.
    delete elemsById.towerhillfenchurchstreet;

    setGameStations(elemsById);
    setGameAllStations(elemsById);
    setGameTotalStations(Object.keys(elemsById).length);

    // Hide the names.
    hideElems(nameElems);
  }, []);

  // Start the game timer when the "in progress" state of the game becomes true.
  useEffect(() => {
    if (gameInProgress) {
      const interval = setInterval(() => {
        setGameTimeElapsed((seconds) => seconds + 1);
      }, 1000);

      setGameTimer(interval);

      return () => {
        clearInterval(interval);
        setGameTimer(null);
      };
    }

    return null;
  }, [gameInProgress]);

  const handleStop = useCallback(() => {
    setGameInProgress(false);
    setModalStopVisible(false);
    setModalEndVisible(true);
    showElems(stationNameElems);
    clearInterval(gameTimer);

    stationNameElems.forEach((elem) => {
      const children = elem.querySelectorAll('text, tspan');
      const id = stringToStationId(elem.textContent);

      [...children, elem].forEach((child) => {
        if (gameStations[id]) {
          child.setAttribute('fill', '#a94442');
        }
      });
    });
  }, [gameTimer, stationNameElems, gameStations]);

  useEffect(() => {
    if (gameTimeElapsed >= TIME_LIMIT) {
      handleStop();
    }
  }, [gameTimeElapsed, handleStop]);

  // Handle clicks on the "Start" button within the intro modal. Hides the intro
  // modal and begins the game itself.
  const handleStart = () => {
    setModalIntroVisible(false);
    setGameInProgress(true);
  };

  const handleRestart = () => {
    stationNameElems.forEach((elem) => {
      const children = elem.querySelectorAll('text, tspan');
      const id = stringToStationId(elem.textContent);

      [...children, elem].forEach((child) => {
        if (gameStations[id]) {
          child.setAttribute('fill', '#1c3f94');
        }
      });
    });

    hideElems(stationNameElems);
    setGameStations(gameAllStations);
    setGameScore(0);
    setGameFoundStations(0);
    setGameTimeElapsed(0);
    setModalEndVisible(false);
    gameZoomer.zoom(3);
    setGameInProgress(true);
  };

  const handleBrowse = () => {
    setModalEndVisible(false);
    setActionsVisible(false);
    setActionsEndVisible(true);
  };

  const handleFinishBrowse = () => {
    setModalEndVisible(true);
    setActionsEndVisible(false);
    setActionsVisible(true);
  };

  // Handle text input from the answer bar.
  const handleAnswerInput = (e) => {
    if (gameInProgress) {
      const id = stringToStationId(e.target.value);
      const match = gameStations[id];

      // If the string matched a station we can reveal the relevant elements. We
      // also remove the matched nodes from the map so you can't repeatedly
      // match one station (which is important to allow matching longer strings
      // with the same prefix e.g. Aldgate -> Aldgate East).
      if (match && match.length) {
        let targetCoords;

        match.forEach((node, i) => {
          node.style.display = 'block';

          // Special case to handle anomalies in the base SVG where there are 2
          // nested nodes with the same id (the only case noticed so far is
          // Tower Hill).
          if (node?.parentNode?.style?.display === 'none') {
            node.parentNode.style.display = 'block';
          }

          // If the node in question has a transform that we can obtain its
          // coordinates from, and we have not already found one, we record the
          // coordinates so we can centre the viewport on the found station.
          //
          // TODO: Some station nodes (e.g. West Ham) don't have any transforms
          // and therefore don't get centered on the screen.
          if (!targetCoords && node.transform.baseVal.length) {
            const { matrix } = node.transform.baseVal.getItem(0);
            const { e: x, f: y } = matrix;

            targetCoords = { x, y };
          }
        });

        // Centre the viewport on the found station if possible.
        if (targetCoords) {
          const { realZoom } = gameZoomer.getSizes();

          gameZoomer.pan({
            x: (windowSize.width / 2) - (targetCoords.x * realZoom),
            y: (windowSize.height / 2) - (targetCoords.y * realZoom),
          });
        }

        // Clear the answer bar text input.
        e.target.value = '';

        console.log('FOIUND ID', id, gameStations[id]);

        // Update state.
        setGameScore((score) => score + BASE_SCORE);
        setGameStations((oldGameStations) => {
          const newGameStations = { ...oldGameStations };
          delete newGameStations[id];

          return newGameStations;
        });
        setGameFoundStations((oldGameFoundStations) => oldGameFoundStations + 1);
      }
    }
  };

  const handleClickStop = () => {
    setModalStopVisible(true);
  };

  const handleStopCancel = () => {
    setModalStopVisible(false);
  };

  const handleStopConfirm = () => {
    handleStop();
  };

  return (
    <main>
      <div ref={svg}>
        <ModalIntro
          onStart={handleStart}
          totalStations={gameTotalStations}
          visible={modalIntroVisible}
        />
        <ModalEnd
          onClickBrowse={handleBrowse}
          onClickRestart={handleRestart}
          score={gameScore}
          foundStations={gameFoundStations}
          totalStations={gameTotalStations}
          visible={modalEndVisible}
        />
        <ModalStop
          onCancel={handleStopCancel}
          onConfirm={handleStopConfirm}
          visible={modalStopVisible}
        />
        <ActionsPlaying
          onAnswerInput={handleAnswerInput}
          onClickStop={handleClickStop}
          timeElapsed={gameTimeElapsed}
          timeStart={TIME_LIMIT}
          score={gameScore}
          visible={actionsVisible}
        />
        <ActionsEnd
          onClickFinished={handleFinishBrowse}
          visible={actionsEndVisible}
        />
        <Map />
      </div>
    </main>
  );
}

export default IndexPage;
